import React,{useState} from 'react'
import {Navbar, Nav, NavDropdown, MenuItem} from 'react-bootstrap';
import {FaAngleDown} from 'react-icons/fa';
import {FaAngleUp} from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from '../../assets/prodocs-logo.png';
import {Link, navigate} from 'gatsby';
import './removeCaret.css'
import styled from 'styled-components';
import { useCookies } from 'react-cookie'
import  secureLocalStorage  from  "react-secure-storage";

export default function Menu(props) {
    const {consoleLog} = require('../commonFunctions.js');
    consoleLog("Menu Props>",props);
    
    const [cookies, setCookie, removeCookie] = useCookies(['user_token']);
    const [activeNavBar, setActiveNavBar] = React.useState("document");
    const [upArrowIcon, setUpArrowIcon] = useState(false);
    const navDropdownTitle = (<div className="hover-state d-flex align-items-center justify-content-center title"><p className=" m-0" style={{fontFamily:'Rajdhani',fontSize:'16px', fontWeight:'500'}}>Template Manager</p>{upArrowIcon ? <FaAngleUp color={'#F7941C'} style={{margin:'0 3px'}}  size={15}>  </FaAngleUp> : <FaAngleDown color={'#F7941C'} style={{margin:'0 3px'}}  size={15}>  </FaAngleDown>}</div>);
    if(typeof window !== 'undefined'){
        window.addEventListener('click', () => {
            setUpArrowIcon(false);
        })
    }
    
    var client_f_name = '';
    var client_l_name = '';
    var client_company = '';
    
    if(typeof window !== 'undefined'){
        client_f_name = JSON.parse(secureLocalStorage.getItem('client_data')).first_name;
        client_l_name = JSON.parse(secureLocalStorage.getItem('client_data')).last_name;
        client_company = JSON.parse(secureLocalStorage.getItem('client_data')).company;
        consoleLog('client_data>>>',JSON.parse(secureLocalStorage.getItem('client_data')));
        consoleLog('user_token>>>',secureLocalStorage.getItem('user_token'));
    }

    const [clientName, setClientName] = React.useState(client_f_name+' '+client_l_name);
    const [clientComp, setClientComp] = React.useState(client_company);

    function templateManager(doc_type_id){
        consoleLog("templateManager, doc_type_id: ", doc_type_id);
        if(typeof props.selectType != 'undefined'){
            props.selectType(doc_type_id);
        }
        navigate('/templates?doc_type_id=' + doc_type_id);
    }

    function logout(){
        consoleLog("logout", "()");
        if (typeof window !== 'undefined') {
            secureLocalStorage.removeItem('client_data');
            secureLocalStorage.removeItem('user_token');
            secureLocalStorage.removeItem('es_down_docs');
            if(secureLocalStorage.getItem('admin_data')){
                navigate('/prodocs-admin-login');
            }else{
                navigate('/login');
            }
        }
    }

    const handleDoc = () => {
        navigate('/document')
        setActiveNavBar("document")
    }
    const handleDrop = (e) => {
        e.stopPropagation();
        const show = !upArrowIcon;
        consoleLog("handleDrop",show)
        setUpArrowIcon(show);
        setActiveNavBar("drop")
    }
    
    return (
        <ProdocsNavBar>
            <Navbar style={{padding:'10px', marginBottom:'10px', minHeight:'88px'}} collapseOnSelect expand="lg" className="text-black noWrapNavbar mb-0">
                <Navbar.Brand href="/document" className="px-xl-5 mr-auto mr-lg-0 ml-xl-5 navbar-brand-custom">
                    <img src={Logo} width="120px" className="h-auto nav-landscape" style={{ marginTop:'-3px'}} alt=""/>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse className="pl-lg-3 pl-xl-5" id="responsive-navbar-nav">
                    <div className="custom-flex">
                        <Nav className="text-dark mr-auto mr-lg-0">
                            <Nav.Link onClick={handleDoc} className={`hover-state title csx-width px-0 s_nav_item ${ (props.page == "document") ? "s_nav_item_active" : ""}`} >Documents</Nav.Link>
                            <NavDropdown onClick={handleDrop}  className={`
                            px-0-child
                            title s_nav_item nav-dropdown-styling ${ (props.page == "template") ? "s_nav_item_active" : ""}`} title={navDropdownTitle} id="collasible-nav-dropdown">
                                <NavDropdown.Item
                                href="#" onClick={() => templateManager(1)}  style={{fontSize:"14px", fontFamily:'Rajdhani', color:'#fff', fontWeight:'500'}}
                                >Estimate</NavDropdown.Item>
                                <NavDropdown.Item href="#" onClick={() => templateManager(2)}  style={{fontSize:"14px", fontFamily:'Rajdhani', color:'#fff', fontWeight:'500'}}>Work Order</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                        <Nav className="mr-auto mr-lg-0 mt-md-3 mt-1 mt-lg-0">
                            <Nav.Link href="#" style={{ fontFamily:'Rajdhani',color:'#737F82', fontWeight:'500',fontSize:"16px",}}>Welcome {clientName} | { clientComp && <span style={{fontWeight:'600'}}>{clientComp}</span>}</Nav.Link>
                            { (typeof window !== 'undefined') &&
                                !secureLocalStorage.getItem('user_token') 
                                ?
                                    <Nav.Link href="/login" className="text-lg-center" style={{ fontFamily:'Rajdhani',color:'#737F82', fontWeight:'500',fontSize:"16px"}}>
                                        Login
                                    </Nav.Link>
                                :
                                    <Nav.Link href="javascript:;" onClick={logout} className="text-lg-center hover-state" style={{ fontFamily:'Rajdhani',color:'#737F82', fontWeight:'500',fontSize:"16px"}}>
                                        Logout
                                    </Nav.Link>
                            }
                        </Nav>
                    </div>
                   
                </Navbar.Collapse>
            </Navbar>
        </ProdocsNavBar>
    )
}

const ProdocsNavBar = styled.div`
background:#FFFFFF;
min-height:88px;

.dropdown-menu.show{
    top:0px !important;
    left: unset!important;
    transform: none!important;
    right: 1px;
    top: 100%!important;
}
    w-30{
        width: 30%;
    }

    w-70{
        width: 70%
    }
    .title{
        color:#212E33 !important;
    }
   .csx-width{
         width: 72px;
   }
    .s_nav_item{
        color:#212E33;
        font-size:16px;
        margin-right:47px;
        font-family:Rajdhani;
        font-weight:500 !important;
        margin-bottom:0px !important;
        height:34px;
    }
    .s_nav_item_active{
        border-bottom:2.5px solid #F7941C !important;
    }

    .navbar-toggler {
        @media screen and  (min-width: 767px) and (max-width: 1024px) {
           display: none !important;  
        }
    }

    .hover-state {
        &:hover {
            color: #f7941c !important;
        }
    }

    .custom-flex{
        width:100%;
        display:flex;
        align-items:center;
        justify-content:space-between;


        @media(max-width:985px){
            flex-direction:column;
            justify-content:center;
        }

    }
`